<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>轮播图编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">


                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            :limit="1"
                            :before-upload="beforeUpload"
                            accept=".xls,.xlsx,.mp3"
                    >
                    <el-button
                            class="btn"
                            slot="trigger"
                            size="small"
                            type="primary"
                            style="margin-right: 20px"
                    >导入模板</el-button>

                    </el-upload>



                    <!--

                                        <el-upload
                                                :action="$api.advUpload"
                                                list-type="picture-card"
                                                :on-remove="handleRemove"
                                                :on-success="handleSuccess"
                                                :before-upload="handleBefore"
                                                :file-list="files">
                                            <i class="el-icon-plus"></i>
                                        </el-upload>
                                        <el-dialog v-model="dialogVisible" size="tiny">
                                            <img width="100%" :src="dialogImageUrl" alt="">
                                        </el-dialog>

                                        <el-form-item label="头像" prop="thumb">
                                            <el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                                            <img v-if="info.F_VR_TOUX" :src="info.F_VR_TOUX" class="avatar-upload">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload></el-form-item>


                                        <el-form-item>
                                            <el-upload
                                                    class="upload-demo"
                                                    ref="upload"
                                                    :action="$api.fileUpload"
                                                    :limit="1"
                                                    :file-list="fileList"
                                                    :before-upload="beforeUpload">
                                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                                &lt;!&ndash;<a href="./static/moban.xlsx" rel="external nofollow" download="模板"><el-button size="small" type="success">下载模板</el-button></a>&ndash;&gt;
                                                &lt;!&ndash; <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button> &ndash;&gt;
                                                <div slot="tip" class="el-upload__tip">只能上传mp3文件，且不超过5MB</div>
                                                <div slot="tip" class="el-upload-list__item-name">{{fileName}}</div>
                                            </el-upload>
                                        </el-form-item>

                                        <el-form-item label="排序" prop="adv_sort"><el-input placeholder="请输入内容" type="number"  v-model="info.F_IN_PAIX"></el-input></el-form-item>
                    -->
                    <!--<input type="file"  @change="changeflie" />
                    <el-form-item>
                        <el-upload
                                class="upload-demo"
                                ref="upload"
                                :action="$api.fileUpload"
                                :limit="1"
                                :file-list="fileList"
                                :before-upload="beforeUpload">
                            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                             <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload()">上传到服务器</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传mp3文件，且不超过5MB</div>
                            <div slot="tip" class="el-upload-list__item-name">{{fileName}}</div>
                        </el-upload>
                    </el-form-item>


                    <el-form-item label="文件上传" prop="adv_sort"><el-input placeholder="请输入内容" type="file" name="file" ></el-input></el-form-item>-->
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <!-- <el-button @click="resetForm('info')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          edit_id:0,
          info:{
              adv_sort:0,
              adv_type:0,
              ap_id:0,
              fileName:''
          },
          list:[],
          upload_headers:{},
          fileName:'',
          file:''
      };
    },
    watch: {},
    computed: {},
    methods: {

        changeflie(event){
            let _this = this;
            var files = event.target.files[0];  

            console.log(files)       

            let formData = new FormData();

            formData.append('file', files);

           /* let url = 'http://wechat.cloudliveshop.com/api/shoplatform/wenjsc';
            _this.$post(
                url,
                formData,
                {headers:{ 'Content-Type':'multipart/form-data' }}).then(res=>{
                console.log(res)
            }).catch(err=>{})*/

            /*let url = 'http://wechat.cloudliveshop.com/api/shoplatform/wenjsc';
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            };
            this.axios.post(url,formData,config)
                .then((response) => {
                    console.log(response)
                })*/

           /* axios.post({

                url:"http://wechat.cloudliveshop.com/api/shoplatform/wenjsc",

                data:formData,//在此处上传文件

                method:"post",

                headers:{

                    'Content-Type':'multipart/form-data'//值得注意的是，这个地方一定要把请求头更改一下

                }

            }).then(function(res){

                console.log(res,"此处应该是请求成功的回调")

            }.catch(function(req){

                console.log(req,"请求失败的回调，自己看看为啥失败")

            }))*/



            this.$http
                .post('http://wechat.cloudliveshop.com/api/shoplatform/wenjsc', formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .then(function (response) {
                                if(!response.data){
                                    alert('File not uploaded.');
                                }else{
                                    alert('File uploaded successfully.');
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });




           /* this.$axios({
                url: 'http://wechat.cloudliveshop.com/api/shoplatform/wenjsc',
                method: 'post',
                data: formData,
            }).then((res) => {
                console.log(res)
            })*/
            /*console.log(formData)
            let requestConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
            _this.$post(`http://wechat.cloudliveshop.com/api/shoplatform/wenjsc`, formData, {headers:{ 'Content-Type':'multipart/form-data' }}).then((res) => {

                console.log(res)
            }) */

            /*api.upload(formData).then(res=>{     //请求接口

            }*/

        },




        beforeUpload(file){
           // debugger
            console.log(file,'文件');
            this.files = file;
            const extension = file.name.split('.')[1] === 'mp3'
            const extension2 = file.name.split('.')[1] === 'mp3'
            const isLt2M = file.size / 1024 / 1024 < 10
            if (!extension && !extension2) {
                this.$message.warning('上传模板只能是 mp3格式!')
                return
            }
            if (!isLt2M) {
                this.$message.warning('上传模板大小不能超过 10MB!')
                return
            }
            this.fileName = file.name;
            this.info.fileName =  file.name;
            return false // 返回false不会自动上传
        },

        submitUpload() {
            let _this = this;
            console.log('上传'+this.files.name)
            if(this.fileName == ""){
                this.$message.warning('请选择要上传的文件！')
                return false
            }
            let fileFormData = new FormData();
            fileFormData.append('file', this.files);//filename是键，file是值，就是要传的文件，test.zip是要传的文件名

            console.log(666)
            console.log(fileFormData)
            console.log(666)

            let requestConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
            _this.$post(`http://wechat.cloudliveshop.com/api/shoplatform/wenjsc`, this.files, requestConfig).then((res) => {
                debugger
                console.log(res)
            })
        },


        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;

            let requestConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
            /*this.$http.post(`/basedata/oesmembers/upload?companyId=`+this.company, fileFormData, requestConfig).then((res) => {
                if (data && data.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.visible = false
                            this.$emit('refreshDataList')
                        }
                    })
                } else {
                    this.$message.error(data.msg)
                }
            })*/

            let fileFormData = new FormData();
            fileFormData.append('file', this.files, this.fileName);//filename是键，file是值，就是要传的文件，test.zip是要传的文件名
            _this.$post(`http://wechat.cloudliveshop.com/api/shoplatform/wenjsc`,fileFormData, requestConfig).then(function(res){
                console.log(res)
            });




            // 验证表单
           /* this.$refs[e].validate(function(res){
                if(res){
                    let fileFormData = new FormData();
                    fileFormData.append('file', this.files, this.fileName);//filename是键，file是值，就是要传的文件，test.zip是要传的文件名


                    let url = 'http://wechat.cloudliveshop.com/api/shoplatform/wenjsc';
                    _this.$post(url,fileFormData, requestConfig).then(function(res){

                    });


                }
            });*/


            // 验证表单
            /*this.$refs[e].validate(function(res){
                if(res){
                    //  判断是Add 或者 Edit
                    let url = _this.$api.addLunbt;
                    if(_this.edit_id>0){
                        url = _this.$api.editLunbt+_this.edit_id;
                    }
                    // Http 请求
                    _this.$post(url,_this.info).then(function(res){
                        if(res.code == 200){
                            _this.$message.success("编辑成功");
                            _this.$router.go(-1);
                        }else{
                            _this.$message.error("编辑失败");
                        }
                    });
                }
            });*/
        },
        get_adv_info:function(){
            let _this = this;
            this.$get(this.$api.editLunbt+this.edit_id).then(function(res){
                _this.info = res.data;
                _this.list = res.data.list;
            })

        },
        get_adv_list:function(){
            let _this = this;
            this.$get(this.$api.addLunbt).then(function(res){
                _this.list = res.data;
            })

        },
        selectChange:function(){
            this.$forceUpdate();
        },
        handleAvatarSuccess(res) {
            this.info.F_VR_TUP = res.data;
            this.$forceUpdate();
        },
    },
    created() {
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到

        if(!this.$isEmpty(this.$route.params.adv_position_id)){
            this.info.ap_id = this.$route.params.adv_position_id;
        }

        // 判断是否是编辑
        if(!this.$isEmpty(this.$route.params.id)){
            this.edit_id = this.$route.params.id;
        }

        if(this.edit_id>0){
            this.get_adv_info();
        }else{
            this.get_adv_list();
        }
        
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.line{text-align: center}
</style>